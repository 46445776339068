<template>
  <!-- 运营外部班级 -->
  <div class="pageContol listWrap outClassList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">培训管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">外部班级列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items:flex-start">
          <div class="searchbox" style="margin-bottom:8px">
            <div title="班级编号" class="searchboxItem ci-full">
              <span class="itemLabel">班级编号:</span>
              <el-input
                v-model="projectCode"
                type="text"
                clearable
                size="small"
                placeholder="请输入班级编码"
              />
            </div>
            <div title="班级名称" class="searchboxItem ci-full">
              <span class="itemLabel">班级名称:</span>
              <el-input
                v-model="projectName"
                type="text"
                clearable
                size="small"
                placeholder="请输入班级名称"
              />
            </div>
            <div title="机构名称" class="searchboxItem ci-full">
              <span class="itemLabel">机构名称:</span>
              <el-input
                v-model="sourceCompName"
                type="text"
                clearable
                size="small"
                placeholder="请输入机构名称"
              />
            </div>
            <div title="状态" class="searchboxItem ci-full">
              <span class="itemLabel">状态:</span>
              <el-select
                      size="small"
                      v-model="classstatus"
                      placeholder="请选择班级状态"
                      clearable
                    >
                      <el-option
                        v-for="item in ClassstatusList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
            </div>
          </div>
          <div class="searchbox">
            <div title="开始时间" class="searchboxItem ci-full">
              <span class="listLabel">开始时间:</span>
              <el-date-picker
                class="listInput"
                clearable
                size="small"
                v-model="startTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
            <div title="结束时间" class="searchboxItem ci-full">
              <span class="listLabel">结束时间:</span>
              <el-date-picker
                class="listInput"
                clearable
                size="small"
                v-model="endTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
            <div class="df">
              <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              height="100%"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                fixed
                width="100px"
              />
              <el-table-column
                label="班级编码"
                align="left"
                prop="projectCode"
                show-overflow-tooltip
                fixed
                min-width="200"
              />
              <el-table-column
                label="班级名称"
                align="left"
                prop="projectName"
                show-overflow-tooltip
                width="260"
              />
              <el-table-column
                label="机构名称"
                align="left"
                show-overflow-tooltip
                prop="sourceCompName"
                width="260"
              />
              <el-table-column
                label="行政区划"
                align="left"
                show-overflow-tooltip
                width="100"
              >{{'长春'}}</el-table-column>
              <el-table-column label="负责人" align="left" prop="sourceUser" show-overflow-tooltip />
              <el-table-column
                label="负责人电话"
                align="left"
                width="150"
                prop="sourceUserPhone"
                show-overflow-tooltip
              />
              <el-table-column
                label="开始时间"
                align="left"
                prop="startDate"
                width="120"
                show-overflow-tooltip
              >
                <template slot-scope="scope">{{scope.row.startDate | momentDate}}</template>
              </el-table-column>
              <el-table-column
                label="结束时间"
                align="left"
                prop="endDate"
                width="120"
                show-overflow-tooltip
              >
                <template slot-scope="scope">{{scope.row.endDate | momentDate}}</template>
              </el-table-column>
              <el-table-column
                label="状态"
                align="left"
                prop="auditState"
                width="120"
                show-overflow-tooltip
              >
                <template slot-scope="scope">{{$setDictionary("PROJECTAUDITSTATE", scope.row.auditState) }}</template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="280px" fixed="right">
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding:0px 10px"
                    size="mini"
                    @click="handleStudent(scope.row.projectId)"
                  >学员信息</el-button>
                  <el-button
                    :disabled="scope.row.authentication==='20'"
                    type="text"
                    style="padding:0px 10px"
                    size="mini"
                    @click="pushLearningProgress(scope.row.sourceEndTime,scope.row.projectId)"
                  >重推学习进度</el-button>
                  <el-button
                    :disabled="scope.row.authentication==='20'"
                    type="text"
                    style="padding:0px 10px"
                    size="mini"
                    @click="pushCourseInformation(scope.row.projectId)"
                  >重推课程信息</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "operate/outClassList",
  components: {
    Empty,
    PageNum
  },
  mixins: [List],
  data() {
    return {
      projectName: "",
      outplant: "",
      deletedstate: "true",
      userstate: "",
      projectCode: "",
      sourceCompName: "",
      startTime: "",
      endTime: "",
      classstatus:'',
      ClassstatusList:[],
    };
  },
  computed: {},
   created() {
    this.getclassstatusList();
  },
  methods: {
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize
      };
      if (this.projectName) {
        params.projectName = this.projectName;
      }
      if (this.sourceCompName) {
        params.sourceCompName = this.sourceCompName;
      }
      if (this.projectCode) {
        params.projectCode = this.projectCode;
      }
      if (this.classstatus) {
        params.auditState = this.classstatus;
      }
      if (this.startTime && this.startTime.length > 0) {
        params.startDateStart = this.startTime[0];
        params.startDateEnd = this.startTime[1];
      }
      if (this.endTime && this.endTime.length > 0) {
        params.endDateStart = this.endTime[0];
        params.endDateEnd = this.endTime[1];
      }
      this.doFetch({
        url: "/biz/sou/cc/projectPage",
        params,
        pageNum
      });
    },
    //学员信息
    handleStudent(projectId) {
      this.$router.push({
        path: "/web/operate/studentDetailList",
        query: {
          projectId
        }
      });
    },
    //重推学习进度
    pushLearningProgress(sourceEndTime, projectId) {
      let sourceEndTimes = sourceEndTime.replace(/-/gm, "/");
      console.log(sourceEndTimes);
      let curDay = this.$moment().format("YYYY/MM/DD");
      console.log(curDay);
      if (sourceEndTimes > curDay) {
        this.$confirm("班级尚未结束,是否继续操作？", "提示", {
          confirmButtonText: "确定",
          confirmButtonClass: "bgc-bv",
          cancelButtonText: "取消"
        })
          .then(() => {
            this.doAjax(projectId);
          })
          .catch(() => {
            return;
          });
      } else {
        this.doAjax(projectId);
      }
    },
    doAjax(projectId) {
      this.$post("/interface/cCSkillsTraing/courseSuperviseAssessByProject", {
        projectId
      })
        .then(ret => {
          if (ret.status == "0") {
            this.$message({
              type: "success",
              message: ret.message
            });
          }
        })
        .catch(err => {
          return;
        });
    },
    // 重推课程信息
    pushCourseInformation(projectId) {
      this.$post("/interface/cCSkillsTraing/pushClassTable", {
        projectId
      })
        .then(ret => {
          if (ret.status == "0") {
            this.$message({
              type: "success",
              message: ret.message
            });
          }
        })
        .catch(err => {
          return;
        });
    },
    /**
     * 对接平台
     */
    // getplant(){},
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1 + 40;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
      getclassstatusList() {
      const classstatuslist = this.$setDictionary("PROJECTAUDITSTATE", "list");
      const list = [];
      for (const key in classstatuslist) {
        list.push({
          value: key,
          label: classstatuslist[key],
        });
      }
      this.ClassstatusList = list;
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less">
.addlist {
  .el-icon-plus:before {
    content: "\e6d9";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
// .el-table--border::after, .el-table--group::after{
//   height: 0;
// }
// .el-table--enable-row-transition .el-table__body td:first-child{
//   border-left: 1px solid #EBEEF5;
// }
// .el-table--border, .el-table--group{
//   border: 0;
// }
</style>
<style lang="less" scoped>
.addlist {
  h3 {
    padding: 10px 0;
  }
}
.zsBtn {
  margin-left: 20px;
  button {
    height: 40px;
  }
}
.certificate-box {
  > div:last-child {
    border: 0;
  }
}
.card-border {
  padding: 10px;
  border-bottom: 1px dashed #eee;
}
</style>
